import React from 'react'
import * as st from '~/assets/styl/obrigado.module.styl'

import { Link } from 'gatsby'

import galleryBg from '~/assets/img/gallery-bg.jpg'

const Obrigado = () => (
  <>
    <section
      className={st.thankYou}
      style={{ backgroundImage: `url(${galleryBg})` }}
    >
      <div>
        <h1>Mensagem enviada com sucesso.</h1>
        <p>
          Sua mensagem foi recebida por nossa equipe.
          <br /> Em breve entraremos em contato para melhor atendê-lo.
        </p>
      </div>
      <Link to="/" className={st.back}>
        Voltar para o site
      </Link>
    </section>
  </>
)

export default Obrigado
